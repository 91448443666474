<template>
    <section class="simple">
        <div class="wrap">
            <div v-if="sectionTitle" class="row">
                <div class="simpleTitle col-12 px-2">
                    <h2 class="h3">
                        <span class="icon"><img :src="sectionIcon" :alt="sectionTitle"></span> 
                        <span class="val">{{ sectionTitle }}</span>
                    </h2>
                </div>
            </div>

            <div class="row aic" v-for="(banner, idx) in banners" :key="'simpleBannersSingle_' + idx" >
                <div class="simpleImg col-12 col-lg-6 px-2">
                    <img :src=banner.image :alt=this.getBannerTitle(banner) v-if="banner.image">

                    <template v-if="banner.videos">
                        <video width="100%" height="480" controls v-if="banner.videos.length == 1" poster="@/assets/dist/img/poster.png">
                            <source :src="banner.videos[0].url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>

                        <VideoListSlider v-else :video-object="banner.videos" />

                        <!-- <template v-else>
                            SLIDER
                            <ul>
                                <li v-for="(vid, idx) in banner.video" 
                                :key="'bannerVideo_' + idx">
                                {{ vid }}
                                </li>
                            </ul>
                        </template> -->
                    </template>
                </div>
                <div class="simpleText col-12 col-lg-6 px-2">
                    <h2>{{ this.getBannerTitle(banner) }}</h2>
                    <div v-html="this.getBannerContent(banner)"></div>
                    <a :href="this.getBannerLink(banner)"  @click="this.report()" class="btn" v-if="this.getBannerLink(banner)">
                        {{ this.getBtnLabel(banner) }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VideoListSlider from "@/views/partials/VideoListSlider.vue"

export default {
    name: 'SimpleSection',
    props: {
        'banners': Array,
        'sectionTitle': String,
        'sectionIcon': {}
    },
    components: {
        VideoListSlider
    },

    watch: {
        'banners': function () {
            //console.log('watch banners in simple section', this.banners);
        },
        'sectionTitle': function () {
            //console.log('watch sectionTitle', this.sectionTitle);
        }
    },
    methods: {
        getBtnLabel(banner) {
            return banner['btn_label_'+this.$store.state.lang];
        },
        getBannerLink(banner) {

            return banner['link_'+this.$store.state.lang];
        },
        getBannerTitle(banner) {
            if (this.$store.state.lang == 'cs')
                return banner.title_cs;
            else if (this.$store.state.lang == 'en')
                return banner.title_en;
            else if (this.$store.state.lang == 'uk')
                return banner.title_uk;
        },
        getBannerContent(banner) {
            if (this.$store.state.lang == 'cs')
                return banner.content_cs;
            else if (this.$store.state.lang == 'en')
                return banner.content_en;
            else if (this.$store.state.lang == 'uk')
                return banner.content_uk;
        },
        report() {
          if(this.$route.name != 'home')
            return;

          var clickInfoObject = {
            "ctaName" : "More Information",
            "ctaType" : "Button",
            "ctaLocation" : "Body",
            "ctaTitle" : "Complementary enterprises",
          };

          window.adobeDataLayer.push({ "event" : "ctaClick",
            "clickInfo" : clickInfoObject});
        }
    }
}
</script>
