<template>
  <div v-bind:class="{headLang: this.location=='header', 'footLang col-12 col-lg-auto order-lg-12 ta-c': this.location=='footer', 'mx-a': this.onMapSidebar}" >
    <p v-if="this.location=='footer'">{{$t('language')}}
      <a class="btn btn-ghost btn-rad" v-bind:data-oki=this.getWidghetIdWithHash() v-bind:aria-expanded="this.isOpen" @click="handleExpanderClick()">
        <span class="lang">{{ this.getCurrentLangToShow() }}</span>
        <span class="icon icon-down"></span>
      </a>
    </p>

    <p class="mobile pr-1" v-if="this.location=='header'">Język:</p>
    <a v-if="this.location=='header'" class="btn btn-ghost btn-rad" v-bind:data-oki=this.getWidghetIdWithHash() v-bind:aria-expanded="this.isOpen" @click="handleExpanderClick()">
      <span class="lang">{{ this.getCurrentLangToShow() }}</span>
      <span class="icon icon-down"></span>
    </a>

    <div class="okiCollapse okiDropdown" v-bind:class="{open: this.isOpen, okiDropdownUp: this.location=='footer'}" v-bind:id=this.getWidghetId()>
      <div class="row">
        <div class="col-12 row jcc gap p-2 bdb">
          <a class="col-auto btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentLang() == 'CS'}" @click="handleLangChange('cs')"><span class="btn-val">CS</span></a>
          <a class="col-auto btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentLang() == 'EN'}" @click="handleLangChange('en')"><span class="btn-val">EN</span></a>
<!--          <a class="col-auto btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentLang() == 'UK'}" @click="handleLangChange('uk')"><span class="btn-val">UA</span></a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LangSwitcher',
  // props: ['location'],
  props: {
    onMapSidebar: {
      default: false
    },
    location: {}
  },
  data() {
    return {
      isOpen: false,
      langLocally: null,//this.$store.state.lang,
    }
  },
  methods: {
    getWidghetIdWithHash() {
      return '#'+ this.getWidghetId();
    },
    getWidghetId() {
      return this.location == 'header' ? 'lang' : 'footLang';
    },
    handleExpanderClick() {
      if(this.isOpen) {
        this.isOpen = false;
        document.body.classList.remove('okiCollapseOpen');
      } else {
        this.isOpen = true;
        document.body.classList.add('okiCollapseOpen');
      }
    },
    getCurrentLang() {
      if(this.langLocally != null)
        return this.langLocally.toUpperCase();
      else {
        return this.$store.state.lang.toUpperCase();
      }
    },
    getCurrentLangToShow() {
      var lang = this.getCurrentLang();
      if(lang == 'UK')
        return 'UA';
      else
        return lang;
    },
    handleLangChange(lang) {
      this.langLocally = lang;
      this.$root.$i18n.locale = this.langLocally;
      this.$store.dispatch('changeLang', this.langLocally);

      if(this.$root.$i18n.locale == 'uk') {
        document.body.classList.add('uk');
      } else {
        document.body.classList.remove('uk');
      }

      this.$router.push({name: this.$router.name, params: {'lang': this.$store.state.lang}});

      this.isOpen = true;
      this.handleExpanderClick();
      // var langToPass = lang;
      // if(lang == 'uk')
      //   langToPass = 'uk';
      // window.OneTrust.changeLanguage(langToPass);
      this.report();
    },
    report() {
      var clickInfoObject = {
        "ctaName" : "ChangeLang",
        "ctaType" : "Button",
        "ctaLocation" : "header",
        "ctaTitle" : this.langLocally
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});
    },
  }
}
</script>