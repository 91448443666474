<template>
    <div class="mapPopup " :class="{ open:isOpen }">
        <div class="wrapper">
            <a href="#" class="close" @click.prevent="handleClose"><span class="icon icon-x2"></span></a>
            <div class="row">
                <div class="placeListSingle px-2 pb-2">
                    <div class="row">
                        <div class="img col-3">
                            <a :href="this.buildSrealityLink(selectedEstate)" @click="this.report(selectedEstate.title)" target="_blank">
                                <img :src="selectedEstate.image" :alt="selectedEstate.title">
                            </a>
                        </div>
                        <div class="desc col-9">
                            <p class="loc">{{ selectedEstate.title }}</p>
                            <p class="adress">{{ selectedEstate.district }}</p>
                            <p class="price color_orange" v-if="selectedEstate.price">{{ selectedEstate.price.toLocaleString() }} Kč</p>
                            <p class="details">
                                <span class="val" v-for="(single, idx) in this.getDetails(selectedEstate)" :key="'placeListSingle_details_' + idx" >
                                    {{ single }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="action col-12 px-2">
                    <a :href="this.buildSrealityLink(selectedEstate)" @click="this.report(selectedEstate.title)" target="_blank" class="btn btn-red btn-full"><span class='icon icon-morizon'></span> <span class="val">{{$t('quiz.results.real_estate.show_in_morizon')}}</span></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalMapPopup',
    expose: ['handleOpen', 'handleClose'],
    props: {
        selectedEstate: {},
    },
    data() {
        return {
            isOpen: false,
            offer: null
        }
    },
    methods: {
        getDetails(single) {

          var list = [];
          if(single.square != null)
            list.push(single.square + ' m²');
          if(single.rooms != null) {
            if(single.rooms == 1)
              list.push(single.rooms + ' pomieszczenie');
            else if(single.rooms > 1 && single.rooms < 5)
              list.push(single.rooms + ' pomieszczenia');
            else
              list.push(single.rooms + ' pomieszczeń');
          }

          return list;

        },
        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
            this.$emit('closeOfferPopup', true);
        },
        report(label) {

          var clickInfoObject = {
            "ctaName" : " See on Morizon.pl ",
            "ctaType" : "Button",
            "ctaLocation" : "Body",
            "ctaTitle" : label
          };

          window.adobeDataLayer.push({ "event" : "ctaClick",
            "clickInfo" : clickInfoObject});

        },
        buildSrealityLink(selectedEstate) {
            var country = 'CZ';
            if(this.$store.state.lang == 'en')
              country = 'EN';
            return selectedEstate.url + '?utm_source=KZP_'+country+'&utm_medium=map&utm_campaign=KZP'
        }
    }
}
</script>