<template>
    <ContactView v-if="this.getPage() != null && this.page.isContact == true" />

    <template v-if="(this.page != null && this.page.isContact == false) || this.article != null" >

        <GlobalBannerSection />

        <PagePowerCouplesSection v-if="this.page != null && this.page.includePowerCouples" />

        <PartnersSection
            :headline="this.getPartnerBannersTitle()"
            :icon="this.getPartnerBannersIco()"
            :partners="this.getPartnerBanners()"
            v-if="this.getPartnerBanners().length > 0"
        />

        <WysiwygSection :content="this.getPageContent()" v-if="this.getPageContent()"/>

        <SimpleSection :banners="this.getBanners()" :section-title="this.getBannersTitle()" :section-icon="this.getBannersIco()" />

        <SimpleSection v-if="this.getVideoBanners().length > 0" :banners="this.getVideoBanners()"
            :section-title="this.getVideosTitle()"
            :section-icon="this.getVideosIco()" />

        <QuizSection class="p" :is-subpage="true" />

        <!-- <ArticlesSection /> -->
        <NewsSection :news-section-title="tempNewsSectionTitle" v-if="this.hasNews" class="bg_w bg_gl-lg" />

        <FaqSection :faq-object="this.getFaq()" class="bg_w" />
    </template>
    
    <NotFoundView v-if="this.article == null && this.page == null" />
</template>

<script>
import ContactView from "@/views/ContactView.vue";
import PartnersSection from "./sections/PartnersSection.vue";
import GlobalBannerSection from "./sections/GlobalBannerSection.vue";
import WysiwygSection from "./sections/WysiwygSection.vue";
import SimpleSection from "./sections/SimpleSection.vue";
import FaqSection from "./sections/FaqSection.vue";
import QuizSection from "./sections/QuizSection.vue";
import NewsSection from "./sections/NewsSection.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import PagePowerCouplesSection from "@/views/sections/PagePowerCouplesSection.vue";
// import ArticlesSection from "@/views/sections/ArticlesSection.vue";

export default {
    name: 'PageView',
    components: {
        // ArticlesSection,
        ContactView,
        PartnersSection,
        GlobalBannerSection,
        WysiwygSection,
        SimpleSection,
        FaqSection,
        QuizSection,
        NewsSection,
        NotFoundView,
        PagePowerCouplesSection
    },
    data() {
        return {
            page: null,
            article: null,
            faq: [],
            news: [],
            hasNews: false,
            tempNewsSectionTitle: {
                title: "Dolor sit amet",
                icon: "@/assets/dist/img/newsImg01.svg"
            }
        }
    },
    mounted() {
        //subscribing changes in the store
        this.$store.subscribe(() => {
            this.getPage();
            this.getArticle();
        });
        this.page = this.getPage();

        this.$store.dispatch('fetchArticles').then((data) => {
            if (data.length > 0) {
                this.hasNews = true;
            }
        });
    },
    // watch: {
    //     tempSimpleSectionTitle() {
    //     },
    //     partnersObject() {
    //     }
    // },
    methods: {
        getPageContent() {
          if(this.page != null) {
            return this.page != null ? this.page['content' + (this.$store.state.lang != 'cs' ?
                this.$store.state.lang.charAt(0).toUpperCase() + this.$store.state.lang.slice(1) :
                '')] : '';

          }

          if(this.article != null) {
            return this.article != null ? this.article['content' + (this.$store.state.lang != 'cs' ?
                this.$store.state.lang.charAt(0).toUpperCase() + this.$store.state.lang.slice(1) :
                '')] : '';
          }
          return '';

        },
        getPage() {

            var currentSlug = this.$route.params.slug;
            for(var index in this.$store.state.pages) {
                var pageSlugs = [this.$store.state.pages[index].slug, this.$store.state.pages[index].slugEn, this.$store.state.pages[index].slugUk];
                if(pageSlugs.indexOf(currentSlug) != -1) {
                    this.page = this.$store.state.pages[index];
                    break;
                }
            }

            return this.page;
        },
        getArticle() {

          var currentSlug = this.$route.params.slug;
          for(var index in this.$store.state.articles) {
            var articleSlugs = [this.$store.state.articles[index].slug, this.$store.state.articles[index].slugEn, this.$store.state.articles[index].slugUk];
            if(articleSlugs.indexOf(currentSlug) != -1) {
              this.article = this.$store.state.articles[index];
              break;
            }
          }

          return this.article;
        },
        getFaq() {
            var page = this.getPage() != null ? this.getPage() : this.getArticle();

            if('faq' in page) {
                this.faq = [];
                for(var index in page.faq) {
                    this.faq.push({
                        'id': page.faq[index].id,
                        'question': page.faq[index]['question_' + this.$store.state.lang],
                        'answer': page.faq[index]['answer_' + this.$store.state.lang],
                    });
                }
                return this.faq;
            } else
                return [];
        },
        getBanners() {
            var page = this.getPage();

            if(page != null && 'textBanners' in page) {
                return page.textBanners;
            } else
                return [];
        },
        getVideoBanners() {
          var page = this.getPage();

          if(page != null && 'videoBanners' in page) {
            return page.videoBanners;
          } else
            return [];
        },
        getPartnerBanners() {
          var page = this.getPage();

          if(page != null && 'partnerBanners' in page) {
            return page.partnerBanners;
          } else
            return [];
        },
        getBannersTitle() {
            var page = this.getPage();

            if(page != null && 'textBannersHeadline' in page) {
                return page.textBannersHeadline;
            } else
                return [];
        },
        getBannersIco() {
            var page = this.getPage();

            if(page != null && 'textBannersIcon' in page) {
                return page.textBannersIcon;
            } else
                return [];
        },
        getVideosTitle() {
          var page = this.getPage();

          if(page != null && 'videoBannersHeadline' in page) {
            var langPrefix = this.$store.state.lang != 'cs' ? this.$store.state.lang.charAt(0).toUpperCase() + this.$store.state.lang.slice(1) : '';
            return page['videoBannersHeadline'+langPrefix];
          } else
            return [];
        },
        getVideosIco() {
          var page = this.getPage();

          if(page != null && 'videoBannersIcon' in page) {
            return page.videoBannersIcon;
          } else
            return [];
        },
        getPartnerBannersTitle() {
          var page = this.getPage();

          if(page != null && 'partnerBannersHeadline' in page) {
            var langPrefix = this.$store.state.lang != 'cs' ? this.$store.state.lang.charAt(0).toUpperCase() + this.$store.state.lang.slice(1) : '';
            return page['partnerBannersHeadline'+langPrefix];
          } else
            return [];
        },
        getPartnerBannersIco() {
          var page = this.getPage();

          if(page != null && 'partnerBannersIcon' in page) {
            return page.partnerBannersIcon;
          } else
            return [];
        }

    }
}
</script>