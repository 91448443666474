<template>
    <div class="powerCouple col-12 py-2 bg_gl" :class="{'powerCoupleTriple': this.currentSelectedCat != null && this.currentSelectedCat.powerCouples.length > 1}" v-if="onMap && this.currentSelectedCat != null && this.currentSelectedCat.powerCouples.length > 0">
        <div class="row">
            <div class="powerCoupleTitle col-12 px-2 pb-2">
                <h4>{{$t('results.sidebar.power_section.headline')}}</h4>
                <p>{{$t('results.sidebar.power_section.desc')}}</p>
            </div>
            <div class="col-12 mb-2 px-2 powerCoupleBoxDropWrapper">
              <a href="#" @click="this.handleDropdownClick" class="cat01" data-oki="#cat01" :aria-expanded="this.dropdownOpen">
                <!-- <span class='icon icon-case'></span>  -->
                <span class="val">{{this.getDropdownLabel()}}</span> 
                <span class='icon icon-down'></span>
              </a>

              <div class="okiCollapse okiDropdown powerCoupleBoxDrop" :class="{'open': this.dropdownOpen}" id="cat01">
                <div class="row">
                  <div class="col-12">
                    <ul>
                      <li v-for="(elem, idx) in this.categories" :key="'cat_'+idx"><a href="#" @click="this.handleSelectCategory(elem, $event)">{{this.getCategoryName(elem)}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="powerCoupleBox powerCoupleBoxNew col-12 px-2 pb-2">
                <div class="row">
                    <div class="desc col-12 ta-c pb-2">
                      <p v-html="$t('results.sidebar.power_section.label', {'industry': this.getSelectedCategory()})" />
                    </div>
                    <div class="powerCoupleBoxSingle col-12 col-md-6 col-lg-auto">
                      <div class="row aic">
                          <div class="img col-6">
                            <img src="@/assets/dist/img/powerCoupleBoxImg09.svg" alt="lorem">
                              <div class="imgLeft"><img :src="this.currentSelectedCat.iconWhite" alt=""></div>
                              <div class="imgRight"><img :src="this.currentSelectedCat.powerCouples[0].icon" alt=""></div>
                          </div>
                          <div class="desc col-6 ta-c">
                              <div class="descRelatedCatNew">{{this.getPowerCoupleLabel(this.currentSelectedCat.powerCouples[0])}}</div>
                              <div class="descRelatedCatNewRat" data-rat="3"><span></span><span></span><span></span></div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="desc other col-12 mb-2"><p>{{$t('page.power_section.other_match')}}</p></div>
                    <div v-for="(elem, idx) in this.currentSelectedCat.powerCouples" :key="'power_couple_'+idx" class="powerCoupleBoxSingle powerCoupleBoxSingleOther col-12">
                        <template v-if="idx > 0">
                        <div class="row aic">
                            <div class="ico col-auto"><img :src="elem.icon" :alt="$t('results.sidebar.power_section.headline')"></div>
                            <div class="desc col">
                                <div class="descRelatedCatNew">{{this.getRelatedLabel(elem)}}</div>
                            </div>
                            <div class="rat col-auto">
                              <div class="descRelatedCatNewRat" :data-rat="this.currentSelectedCat.powerCouples.length - idx"><span></span><span></span><span></span></div>
                            </div>
                        </div>
                        </template>
                    </div>
                      <!-- <div v-for="(elem, idx) in this.currentSelectedCat.powerCouples" :key="'power_couple_'+idx" class="powerCoupleBoxSingle col-12">
                        <div class="row aic">
                          <div class="img col-6">
                            <img src="@/assets/dist/img/powerCoupleBoxImg06.svg" :alt="$t('results.sidebar.power_section.headline')">
                            <div class="imgLeft"><img :src="this.currentSelectedCat.iconWhite" :alt="$t('results.sidebar.power_section.headline')"></div>
                            <div class="imgRight"><img :src="elem.icon" :alt="$t('results.sidebar.power_section.headline')"></div>
                          </div>
                          <div class="desc col-6 ta-c">
                            <div class="descRelatedCatNew">{{this.getRelatedIndustryInLoop(elem)}}</div>
                          </div>
                        </div>
                      </div> -->
               
                </div>
            </div>
            <div class="powerCoupleMore col-12 ta-c px-2">
                <a :href="this.getReadMoreLink()"><span class="val">{{$t('results.sidebar.power_section.more')}}</span> <span class='icon icon-arrow_right'></span></a>
            </div>
        </div>
    </div>
    <section class="powerCouple" v-else>
        <div class="wrap">
            <div class="row">
                <div class="powerCoupleTitle col-12 col-lg-6 px-2 pb-2">
                    <h4>{{$t('results.sidebar.power_section.headline')}}</h4>
                    <p>{{$t('results.sidebar.power_section.desc')}}</p>
                </div>
                <div class="powerCoupleBox col-12 col-lg-6 px-2 pb-2">
                    <div class="row">
                        <div class="img col-12">
                          <img src="@/assets/dist/img/powerCoupleBoxImg09.svg" :alt="$t('results.sidebar.power_section.headline')">
                          <div class="imgLeft"><img v-if="this.currentSelectedCat" :src="this.currentSelectedCat.iconWhite" :alt="$t('results.sidebar.power_section.headline')"></div>
                          <div class="imgRight"><img v-if="this.currentSelectedCat" :src="this.currentSelectedCat.iconBlack" :alt="$t('results.sidebar.power_section.headline')"></div>
                        </div>
                        <div class="desc col-12">
                            <p v-html="$t('results.sidebar.power_section.label', {'industry': this.getSelectedCategory()})"/>
                            <a href="#" class="btn btn-ghost_orange btn-full"><span class="val">{{ this.getRelatedIndustry() }}</span></a>
                        </div>
                    </div>
                </div>
                <div class="powerCoupleMore col-12 ta-c px-2">
                    <a :href="this.getReadMoreLink()"><span class="val">{{$t('results.sidebar.power_section.more')}}</span> <span class='icon icon-arrow_right'></span></a>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'powerCoupleSectionSelected',
    components: {
    },
    props: {
        onMap: {type: Boolean, default: false}
    },
    data() {
      return {
        selectedCategory: null,
        dropdownOpen: false,
        categories: [],
        label: '',
        currentSelectedCat: null
      }
    },
    mounted() {
      this.label = this.getSelectedCategory();
      this.$store.dispatch('fetchIndustries').then((data) => {
        this.categories = data;
        // if (data.length > 0) {
        //   this.handleSelectFirstCategory(data[0]);
        // }
      });
      this.currentSelectedCat = this.$store.state.industries.find(cat => cat.id === this.$store.state.quiz.industry);
    },
    watch: {
      '$store.state.quiz.industry': function() {
        this.label = this.getSelectedCategory();
        this.currentSelectedCat = this.$store.state.industries.find(cat => cat.id === this.$store.state.quiz.industry);
      },
      '$store.state.quiz.lang': function() {
        this.label = this.getSelectedCategory();
      }
    },
    methods: {
      getRelatedLabel(related) {
        if(this.currentSelectedCat == null) {
          return '';
        }
        var lang = this.$store.state.lang;
        var prefix = lang == 'cs' ? '' : lang == 'en' ? 'En' : 'Uk';
        return related['label'+prefix];
      },
      handleDropdownClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.dropdownOpen = !this.dropdownOpen;
      },
      handleSelectCategory(category, event) {
        event.preventDefault();
        this.currentSelectedCat = category;
        this.dropdownOpen = false;
        this.reportCategorySelection();
      },
      getCategoryName(category) {
        var lang = this.$store.state.lang;
        var prefix = lang == 'cs' ? '' : lang == 'en' ? 'En' : 'Uk';
        return category['name'+prefix];
      },
      getPowerCoupleLabel(elem) {
        var lang = this.$store.state.lang;
        var prefix = lang == 'cs' ? '' : lang == 'en' ? 'En' : 'Uk';
        return elem['label'+prefix];
      },
      getDropdownLabel()
      {
        if(this.currentSelectedCat == null) {
          return this.$t('page.power_section.dropdown_label');
        }
        return this.getCategoryName(this.currentSelectedCat);
      },

      getReadMoreLink() {
        return this.$t('results.sidebar.power_section.more_link');
      },
      getSelectedCategory() {
        if(this.currentSelectedCat == null) {
          return '';
        }
        var langPrefix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang == 'en' ? 'En' : 'Uk';
        return this.currentSelectedCat['name'+langPrefix];
      },
      getRelatedIndustry() {
        if(this.$store.state.quiz.industry == null) {
          return '';
        }

        var langPrefix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang == 'en' ? 'En' : 'Uk';
        for(var idx in this.$store.state.industries) {
          if(this.$store.state.quiz.industry == this.$store.state.industries[idx].id) {

            if(this.$store.state.industries[idx].powerCouples.length > 0)
              return this.$store.state.industries[idx].powerCouples[0]['label'+langPrefix];
          }
        }

        return '';
      },
      getRelatedIndustryInLoop(elem) {
        if(this.$store.state.quiz.industry == null) {
          return '';
        }

        var related = elem;
        var langPrefix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang == 'en' ? 'En' : 'Uk';
        return related['label'+langPrefix];
      },
      getRelatedIndustry2() {
        if(this.$store.state.quiz.industry == null) {
          return '';
        }

        for(var idx in this.$store.state.industries) {
          if(this.$store.state.quiz.industry == this.$store.state.industries[idx].id) {
            if(this.$store.state.industries[idx].powerCouples.length > 0) {
              var related = this.$store.state.industries[idx].powerCouples[0];
              var langPrefix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang == 'en' ? 'En' : 'Uk';
              return related['label'+langPrefix];
            } else
              return '';
          }
        }

        return '';
      },
      reportCategorySelection()
      {
        var clickInfoObject = {
          "ctaName" : "Select your business category ",
          "ctaType" : "Button",
          "ctaLocation" : "Body",
          "ctaTitle" : "Find complementary businesses to inc. profits",
          "fieldValue1":"category:"+this.getCategoryName(this.currentSelectedCat)
        };


        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});

      }
    }
}
</script>
