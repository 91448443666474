<template>
    <div class="mapSidebar" @scroll="handleScroll">
        <div class="row aic">

            <SidebarInfo v-if="emptySidebar" 
                ico="@/assets/dist/img/zoomMap.svg" 
                info="{{$t('results.sidebar.empty.info')}}" />
                <!-- info="Przybliż i kliknij w wybrany obszar aby poznać szczegóły" -->

            <template v-else>
                <!-- stats/list/charts and other components -->
                <!-- :cat-object="this.categories" -->
                <!-- :selected-cat="this.getSelectedCat(categories)" -->
                <CatList 
                    :new-cat-object="this.categoriesList"
                    :new-selected-cat="this.selectedCategory"
                    :square-info="this.squareInfo"
                    :path-number="this.pathNum"
                />

              <PowerCoupleSection
                  v-if="this.$store.state.quiz.industry == null"
                  :on-map="true"
                  @onCategorySelect="this.handlePowerCoupleSelect"
              />
              <PowerCoupleSectionSelected
                  v-if="this.$store.state.quiz.industry != null"
                  :on-map="true"
              />

              <AreaStats
                  v-if="this.getAreaDetails().length > 0"
                  :area-details="this.getAreaDetails()"
                  :selected-square-info="this.getSquareInfo()"
                  @dayOfWeekChange="this.handleChangeDayOfWeekInStats"
              />
              <AreaDetails
                  v-if="this.getAreaDetails().length > 0"
                  :area-details="this.getAreaDetails()"
                  ref="areaDetails"
              />

              <PlaceSection :offers="this.offers"/>

              <TownInfoSection />

              <SaveSection />
              <SummaryRestart />

                <FaqSection 
                    :faq-object="this.getFaq()"
                    :on-map="true"
                    class="bg_gl" />
                
                <LinksSection 
                    :links-list="this.links" 
                    :on-map="true" />

                <GlobalFooter 
                    :on-map="true" />
            </template>
        </div>

        <SidebarScrollIndicator v-if="!emptySidebar" :class="{ hide: isScrolled }" />
    </div>
</template>

<script>
import CatList from '../partials/CatList.vue';
import SidebarInfo from '@/components/SidebarInfo.vue';
import AreaDetails from '../partials/AreaDetails.vue';
import AreaStats from '../partials/AreaStats.vue';
import LinksSection from './LinksSection.vue';
import FaqSection from './FaqSection.vue';
import SaveSection from './SaveSection.vue';
import TownInfoSection from './TownInfoSection.vue';
import PlaceSection from './PlaceSection.vue';
import SidebarScrollIndicator from '@/components/SidebarScrollIndicator.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import PowerCoupleSection from './powerCoupleSection.vue';
import PowerCoupleSectionSelected from "@/views/sections/powerCoupleSectionSelected.vue";
import SummaryRestart from '@/components/SummaryRestart.vue';

export default {
    name: 'MapSidebar',
    data() {
        return {
            isScrolled: this.isMobile ? false : true,
            links: [],
            faq: [],
            page: null,
            article: null,
            emptySidebar: this.notSelected,
            categories: [],
            categoriesList: [],
            selectedCat: {},
            area: null,
            squareInfo: null,
            pathNum: 0
        }
    },
    props: {
        notSelected: {default: true},
        offers: {default: []},
        selectedSquareInfo: {default: null},
        selectedCategory: {}
    },
    components: {
        SidebarInfo,
        CatList,
        AreaDetails,
        AreaStats,
        LinksSection,
        FaqSection,
        SaveSection,
        TownInfoSection,
        PlaceSection,
        SidebarScrollIndicator,
        GlobalFooter,
        PowerCoupleSection,
        PowerCoupleSectionSelected,
        SummaryRestart,
    },
    watch: {
      selectedSquareInfo: function(val) {
        this.squareInfo = val;
        this.area = this.getAreaDetails();

      },
      '$store.state.lang': function() {
        this.area = this.getAreaDetails();
      }
    },
    mounted() {
        this.squareInfo = this.selectedSquareInfo;
        this.area = this.getAreaDetails();
        this.$store.dispatch('fetchPage', 'results').then(page => {
            this.page = page;
        });
        this.$store.dispatch('fetchLinks').then(links => {
            this.links = links;
        });
        if(this.$store.state.industries.length > 0) {
          this.categoriesList = this.$store.state.industries;
        } else {
          this.$store.dispatch('fetchIndustries').then(industries => {
            this.categoriesList = industries;
          });
        }
        window.addEventListener('scroll', this.handleMobileScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleMobileScroll);
    },
    updated() {
      // console.log('this.$store.state.quiz.question_1', this.$store.state.quiz.question_1);
      if (this.$store.state.quiz.question_1 != null) {
        this.pathNum = this.$store.state.quiz.question_1;
      }
      this.squareInfo = this.selectedSquareInfo;
    },
    methods: {
        handlePowerCoupleSelect(category) {
          this.$store.commit('setQuizIndustry', category.id);
          //alert('Selected category: ' + category.name);
        },
        handleChangeDayOfWeekInStats(day) {
          console.log('Event from AreaStats', day);
        },
        getSquareInfo() {
          return this.squareInfo;
        },
        getAreaDetails() {
          if(this.squareInfo == null)
            return [];

          var list = [];

          list.push({
            cat: "air",
            desc: this.$t('quiz.results.area_info.air_quality'),
            val: this.squareInfo.pm25,
            tooltip: this.$t('quiz.results.area_info.air_quality_tooltip')
          });

          // list.push({
          //   cat: "",
          //   desc: this.$t('quiz.results.area_info.education'),
          //   val: this.squareInfo.edu
          // });
          //
          // list.push({
          //   cat: "",
          //   desc: this.$t('quiz.results.area_info.healthcare'),
          //   val: this.squareInfo.healthcare
          // });

          list.push({
            cat: "",
            desc: this.$t('quiz.results.area_info.nuisance'),
            val: this.squareInfo.nuisance,
            tooltip: this.$t('quiz.results.area_info.nuisance_tooltip')
          });

          list.push({
            cat: "",
            desc: this.$t('quiz.results.area_info.transport'),
            val: this.squareInfo.transport
          });

          list.push({
            cat: "",
            desc: this.$t('quiz.results.area_info.green'),
            val: this.squareInfo.green
          });

          list.push({
            cat: "noise",
            desc: this.$t('quiz.results.area_info.noise'),
            val: this.squareInfo.noise,
            tooltip: this.$t('quiz.results.area_info.noise_tooltip')
          });

          return list;
        },
        getFaq() {
          if(this.page == null)
            return [];

          if('faq' in this.page) {
            this.faq = [];
            for(var index in this.page.faq) {
              this.faq.push({
                'id': this.page.faq[index].id,
                'question': this.page.faq[index]['question_' + this.$store.state.lang],
                'answer': this.page.faq[index]['answer_' + this.$store.state.lang],
              });
            }
            return this.faq;
          } else
            return [];
        },
        getSelectedCat(obj) {
            this.selectedCat = obj.filter(o => o.selected == true);
            if (this.selectedCat.length > 0) {
                return this.selectedCat;
            }
        },

        handleScroll (event) {
            // console.log('mapSidebar scroll', event.target.offsetTop, event.target.children[0].offsetTop, event.target.scrollTop);
            if (event.target.scrollTop > 0) {
                // console.log('scrollTop > 0');
                if (!this.isScrolled) {
                    this.isScrolled = true;
                }
            } else {
                // console.log('scrollTop = 0');
                if (this.isScrolled) {
                    this.isScrolled = false;
                }
            }
        },
        handleMobileScroll() {
          var tim_out;
          if (this.isMobile()) {
            clearTimeout(tim_out);

            if (window.scrollY > 0) {
                if (!this.isScrolled) {
                  tim_out = setTimeout(() => {
                    this.isScrolled = true;
                  }, 500);
                }
              } else {
                if (this.isScrolled) {
                  tim_out = setTimeout(() => {
                    this.isScrolled = false;
                  }, 500);
                }
            }
          }
        },
        isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
          } else {
            return false
          }
        },
    }
}
</script>
