<template>
    <div v-if="this.getDownloadSpeed() !== false" class="areaStats col-12 p-2">
        <div class="row">
            <div class="areaStatsTitle col-12"><h4>{{$t('quiz.results.area_info.headline')}}</h4></div>
            <div class="areaStatsTitle col-12">
<!--                <a href="#" data-oki="#areaStatsSingle01" :aria-expanded="[curentOpen == '#areaStatsSingle01' ? true : false]" @click.prevent="handleClickCollapse">{{$t('quiz.results.area_info.density')}}</a>-->
                <a href="#" data-oki="#areaStatsSingle02" :aria-expanded="[curentOpen == '#areaStatsSingle02' ? true : false]" @click.prevent="handleClickCollapse">{{$t('quiz.results.area_info.internet_speed')}}</a>
            </div>

            <div class="areaStatsSingle okiCollapse" :class="{open: curentOpen == '#areaStatsSingle01'}" id="areaStatsSingle01" data-parent=".areaStats">

                <div class="wrapper p-2 ta-c jcc">
                    <div class="col-12 mb-2 areaStatsSingleDropWrapper">
                      <a @click="this.weekDayDropdownOpen = !this.weekDayDropdownOpen" class="areaStatsSingleDrop" data-oki="#areaStatsSingleDrop" aria-expanded="false">
                        <span class="icon icon-cal"></span> <span class="val">{{this.getCurrentDayOfWeek()}}</span> <span class="icon icon-down"></span>
                      </a>
                      <div class="okiDropdown" v-bind:class="{open: this.weekDayDropdownOpen}" id="areaStatsSingleDrop">
                        <div class="row">
                          <div class="col-12">
                            <ul>
                              <li v-for="(elem, idx) in this.days" :key="'day_of_week_'+idx">
                                <a @click="this.handleDayOfWeekChoice(elem)">{{$t(idx)}}</a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="h6">
                        {{$t('map.results.density_of_population')}}
                        <!-- Gęstość zaludnienia  -->
                        <GlobalTooltip 
                            :tooltip-cont="this.$t('map.results.density_of_population_tooltip')"
                            tooltip-id="`area_stats_${$.uid}`" 
                            />
                    </p>
                    <svg class="bar animate" width="300" height="64" viewBox="0 0 300 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g class="rect_anim" transform="scale(1,-1) translate(0,-64)">
                            <rect x="0" y="0" width="300" height="64" fill="url(#grad01)" mask="url(#myMask01)">
                                <animate attributeName="height" from="0" to="64" dur="0.5s" fill="freeze" />
                            </rect>
                        </g>
                        <defs>
                            <mask id="myMask01">
                                <AreaChartBars v-for="(single, index) in chartBarDencity"
                                    :key="single"
                                    :barHeight="single"
                                    :barWidth="(300 / chartBarDencity.length) - 2"
                                    :barX="index * (300 / chartBarDencity.length)"
                                    :barY="64 - single"
                                    />
                            </mask>
                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="grad01">
                                <stop stop-color="#ff671b" stop-opacity="1" offset="2%"></stop>
                                <stop stop-color="#ffc81f" stop-opacity="1" offset="98%"></stop>
                            </linearGradient>
                        </defs>
                        <g class="rectBarHover">
                          <AreaChartBarsHover v-for="(single, index) in chartBarDencity"
                            :key="chartBarDencityUnchanged[index]"
                            :barHeight="single"
                            :barWidth="(300 / chartBarDencity.length) - 2"
                            :barX="index * (300 / chartBarDencity.length)"
                            :barY="64 - single"
                            :proportion="chartBarDencityUnchanged[index]"
                            />
                        </g>
                    </svg>
                    <span class="lineScale">
                      <span class="h"></span>
                      <span></span>
                      <span></span>
                      <span class="h"></span>
                      <span></span>
                      <span></span>
                      <span class="h"></span>
                      <span></span>
                      <span></span>
                      <span class="h"></span>
                      <span></span>
                      <span></span>
                      <span class="h"></span>
                      <span></span>
                      <span></span>
                      <span class="h"></span>
                      <span></span>
                      <span></span>
                    </span>
                    <div class="hours">
                      <span>6 <sup>00</sup></span>
                      <span>9 <sup>00</sup></span>
                      <span>12 <sup>00</sup></span>
                      <span>15 <sup>00</sup></span>
                      <span>18 <sup>00</sup></span>
                      <span>21 <sup>00</sup></span>
                    </div>
                </div>
                <div class="wrapper p-2 ta-c jcc">
                    <p class="h6">
                        {{$t('map.results.move_in_area')}}
                        <!-- Ruch w obszarze -->
                        <GlobalTooltip 
                            :tooltip-cont="this.$t('map.results.move_in_area_tooltip')"
                            tooltip-id="`area_stats_${$.uid}`" 
                            />
                    </p>
                    <svg class="bar animate" width="300" height="64" viewBox="0 0 300 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g class="rect_anim" transform="scale(1,-1) translate(0,-64)">
                            <rect x="0" y="0" width="300" height="64" fill="url(#grad02)" mask="url(#myMask02)">
                                <animate attributeName="height" from="0" to="64" dur="0.5s" fill="freeze" />
                            </rect>
                        </g>
                        <defs>
                            <mask id="myMask02">
                                <AreaChartBars v-for="(single, index) in chartBarTrafick"
                                    :key="single"
                                    :barHeight="single"
                                    :barWidth="(300 / chartBarTrafick.length) - 2"
                                    :barX="index * (300 / chartBarTrafick.length)"
                                    :barY="64 - single"
                                    />
                            </mask>
                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="grad02">
                                <stop stop-color="#ff671b" stop-opacity="1" offset="2%"></stop>
                                <stop stop-color="#ffc81f" stop-opacity="1" offset="98%"></stop>
                            </linearGradient>
                        </defs>
                        <g class="rectBarHover">
                          <AreaChartBarsHover v-for="(single, index) in chartBarTrafick"
                            :key="chartBarTrafickUnchanged[index]"
                            :barHeight="single"
                            :barWidth="(300 / chartBarTrafick.length) - 2"
                            :barX="index * (300 / chartBarTrafick.length)"
                            :barY="64 - single"
                            :proportion="chartBarTrafickUnchanged[index]"
                            />
                        </g>
                    </svg>
                    <span class="lineScale">
                        <span class="h"></span>
                        <span></span>
                        <span></span>
                        <span class="h"></span>
                        <span></span>
                        <span></span>
                        <span class="h"></span>
                        <span></span>
                        <span></span>
                        <span class="h"></span>
                        <span></span>
                        <span></span>
                        <span class="h"></span>
                        <span></span>
                        <span></span>
                        <span class="h"></span>
                        <span></span>
                        <span></span>
                    </span>
                    <div class="hours">
                      <span>6 <sup>00</sup></span>
                      <span>9 <sup>00</sup></span>
                      <span>12 <sup>00</sup></span>
                      <span>15 <sup>00</sup></span>
                      <span>18 <sup>00</sup></span>
                      <span>21 <sup>00</sup></span>
                    </div>
                    <div class="powerBy">{{$t('area_stats_density_powered_by')}}</div>
                </div>
            </div>
            <div class="areaStatsSingle okiCollapse" :class="{open: curentOpen == '#areaStatsSingle02'}" id="areaStatsSingle02" data-parent=".areaStats">
                <div class="wrapper p-2 ta-c jcc">
                    <div class="infoNew ta-c p-2 pt-5 col-12">
                        <p>
                            <span class="name">
                                {{$t('quiz.results.area_info.avg_internet_speed')}}
                            </span>
                            <span class="h3_mob color_orange val">{{this.getDownloadSpeed()}}</span>
                            <span class="pS">Mbps</span>
                        </p>
                    </div>

                    <DownloadWidget :download-speed="this.getDownloadSpeedProportion()"/>

                    <div class="powerBy">{{$t('area_stats_internet_powered_by')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AreaChartBars from '@/components/AreaChartBars.vue';
import AreaChartBarsHover from '@/components/AreaChartBarsHover.vue';
import GlobalTooltip from '@/components/GlobalTooltip.vue';
import DownloadWidget from "@/components/DownloadWidget.vue";

export default {
    name: 'AreaStats',
    components: {
      DownloadWidget,
      AreaChartBars,
      AreaChartBarsHover,
      GlobalTooltip
    },
    data() {
        return {
            selectedDayOfWeek: 1,
            days: {
              'monday': 1,
              'tuesday': 2,
              'wednesday': 3,
              'thursday': 4,
              'friday': 5,
              'saturday': 6,
              'sunday': 7
            },
            chartBarDencity: [60, 23, 40, 46, 54, 63, 64, 61, 60, 55, 54, 55, 40, 39, 22, 20, 10],
            chartBarDencityUnchanged: [60, 23, 40, 46, 54, 63, 64, 61, 60, 55, 54, 55, 40, 39, 22, 20, 10],
            chartBarTrafick: [10, 13, 22, 44, 50, 57, 61, 63, 64, 59, 55, 50, 46, 45, 29, 22, 2],
            chartBarTrafickUnchanged: [10, 13, 22, 44, 50, 57, 61, 63, 64, 59, 55, 50, 46, 45, 29, 22, 2],
            chartPie: {
                percent: parseInt( 433 * .12 ),
                upload: 369,
                download: 963

            },
            curentOpen: '#areaStatsSingle02',
            weekDayDropdownOpen: false,
            areaData: null,
            squareInfo: null,
            downloadSpeedProportion: 245,
            density: null,
            maxDensity: 15000,
            maxVisits: 100000
        }

        // chartPie [.1: 90%, .4: 60%, .6: 40%, .9: 10%]
    },
    props: {
      areaDetails: {default: []},
      selectedSquareInfo: {type: Object}
    },
    watch: {
      areaDetails: function(val) {
        this.areaData = val;
      },
      squareInfo: function(val) {
        this.areaData = val;
        this.getDensityData();
      },
      selectedSquareInfo: function(val) {
        this.squareInfo = val;
        this.getDownloadSpeedProportion();
      }
    },
    beforeMount() {
      var date = new Date();
      if(date.getDay() == 0) {
        this.selectedDayOfWeek = 7;
      } else {
        this.selectedDayOfWeek = date.getDay();
      }
    },
    mounted() {
      this.areaData = this.areaDetails;
      this.squareInfo = this.selectedSquareInfo;
      this.getDensityData();
    },
    updated() {
        this.areaData = this.areaDetails;
        this.squareInfo = this.selectedSquareInfo;
    },


    methods: {
        getDensityData() {
          this.$store.dispatch('fetchSquareDensity', {'squareId': this.squareInfo.externalId}).then((response) => {
            this.density = response.list;
            this.maxDensity = response.density;
            this.maxVisits = response.visits;
            this.calcDensity();
          });
        },
        calcDensity() {
          var densityList = [];
          var densityListUnchanged = [];
          var visitsList = [];
          var visitsListUnchanged = [];

          for(var idx in this.density) {

            if(this.density[idx].dayOfWeek == this.selectedDayOfWeek) {
              densityList.push(this.density[idx].density / this.maxDensity * 100 * (3/5));
              densityListUnchanged.push(this.density[idx].density);
              visitsList.push(this.density[idx].visits / this.maxVisits * 100 * (3/5));
              visitsListUnchanged.push(this.density[idx].visits);
            }
          }

          this.chartBarDencity = densityList;
          this.chartBarDencityUnchanged = densityListUnchanged;
          this.chartBarTrafick = visitsList;
          this.chartBarTrafickUnchanged = visitsListUnchanged;
        },
        handleDayOfWeekChoice(dayIndex) {
          this.selectedDayOfWeek = dayIndex;
          this.weekDayDropdownOpen = false;
          this.$emit('dayOfWeekChange', dayIndex);
          this.calcDensity();
        },
        getCurrentDayOfWeek() {
          for(var name in this.days) {
            if(this.days[name] == this.selectedDayOfWeek) {
              return this.$t(name);
            }
          }
          return '';
        },
        getDownloadSpeedProportion() {
          if(this.areaData != null) {
            if(this.areaData.download != null) {

              this.downloadSpeedProportion = this.areaData.download * 300 / 1000;
            }
          }
          if(this.squareInfo != null) {
            if(this.squareInfo.download != null) {
              this.downloadSpeedProportion = this.squareInfo.download * 300 / 1000;
            }
          }
          return this.downloadSpeedProportion;
        },
        getDownloadSpeed() {
            // console.log('getDownloadSpeed', this.areaData, this.squareInfo);
            if(this.areaData != null) {
                if(this.areaData.download != null) {
                    // bar width = max internet speed
                    // 300 = 10000
                    // x = this.areaData.download
                    this.downloadSpeedProportion = this.areaData.download * 300 / 10000;
                    return this.areaData.download;
                }
            }
            if(this.squareInfo != null) {
                if(this.squareInfo.download != null) {
                    this.downloadSpeedProportion = this.squareInfo.download * 300 / 10000;
                    return this.squareInfo.download;
                }
            }
            return false;
        },
        handleClickCollapse(event) {
            // console.log('handleClickCollapse event', event, this.curentOpen);
            if (this.curentOpen != event.target.dataset.oki) {
                this.curentOpen = event.target.dataset.oki;
            }
        }
    }
}
</script>