<template>
    <div class="menuWrapper">
        <ul>
            <li v-for="item in this.menuItems" v-bind:key="item">
                <router-link v-if="item.local_page == true" @click="this.handleSateliteClick($event)" :to="{ name: 'page', params: {'lang': this.$store.state.lang, 'slug': this.getMenuItemSlug(item)}}">
                    {{ this.getMenuItemLabel(item) }}
                </router-link>
                <a v-if="item.local_page == false" v-bind:href=this.getMenuItemLink(item) @click="this.handleSateliteClick($event)" target="_blank">
                    {{ this.getMenuItemLabel(item)}}
                </a>
            </li>
        </ul>
    </div>
</template>
<script>

export default {
    name: 'GlobalMenuTop',
    components: {
    },
    props: {
    },
    data() {
      return {
        menuItems: []
      }
    },
    mounted() {
      var thisComponent = this;
      this.$store.dispatch('fetchMenu', 'Top').then(items => {
        thisComponent.menuItems = items;

      });
      console.log(this.menuItems);
    },
    methods: {
        getMenuItemSlug(menuItem) {
          if(menuItem.local_page != true)
            return '';

          return this.getMenuItemLink(menuItem);

        },
        getMenuItemLink(menuItem) {
          if(this.$store.state.lang == 'en')
            return menuItem.link_en;
          else if(this.$store.state.lang == 'cs')
            return menuItem.link_cs;
          else if(this.$store.state.lang == 'uk')
            return menuItem.link_uk;
        },
        getMenuItemLabel(menuItem) {
          if(this.$store.state.lang == 'en')
            return menuItem.label_en;
          else if(this.$store.state.lang == 'cs')
            return menuItem.label_cs;
          else if(this.$store.state.lang == 'uk')
            return menuItem.label_uk;
        },
        handleSateliteClick(event) {

            var clickInfoObject = {
              "ctaName" : "Menu Item Click",
              "ctaType" : "Link",
              "ctaLocation" : "TopMenu",
              "ctaTitle" : event.target.innerText,
            };

            window.adobeDataLayer.push({ "event" : "ctaClick",
              "clickInfo" : clickInfoObject});

              
            this.$emit('menu-link-click');
        }
    }
}
</script>