<template>
    <div class="newsList newsListSlider col-12 px-1">
        <carousel 
            ref="newsListCarousel"
            v-model="newsListCurrentSlide" 
            v-bind="newsListSettings" 
            :breakpoints="newsListBreakpoints"
            :transition="500"
            :wrap-around="true"
            >

            <slide v-for="(single, idx) in articles" :key="'newsListSingle_' + idx">
                <GlobalNewsListSingle
                    :img="single.thumb"
                    :title="this.getTitle(single)"
                    :desc="this.getExcerpt(single)"
                    :slug="this.getSlug(single)"
                    />
            </slide>
            
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>

        <div class="newsListSliderNav">
            <a href="#" @click.stop.prevent="prev"><span class='icon icon-prev'></span></a>
            <!-- <input type="number" min="0" max="9" v-model="currentSlide" /> -->
            <!-- <div>{{ currentSlide }}</div> -->
            <a href="#" @click.stop.prevent="next"><span class='icon icon-next'></span></a>
        </div>
    </div>
</template>

<script>
import GlobalNewsListSingle from '@/components/GlobalNewsListSingle.vue';

// import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: 'newsListSlider',
    data() {
        return {
            articles: [],
            // carousel settings
            newsListCurrentSlide: 1,
            newsListSettings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            newsListBreakpoints: {
                390: {
                    itemsToShow: 1.3,
                    snapAlign: 'end',
                },
                768: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
            },
            // carousel settings
        }
    },
    created() {
        this.$store.dispatch('fetchArticles').then((data) => {
            this.articles = data;
        });
    },
    components: {
        GlobalNewsListSingle,
        Carousel,
        Slide,
        Pagination,
    },
    props: {
        // newsObject: {type: Array}
    },
    methods: {
        getTitle(article) {
            var lang_suffix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang;
            if(lang_suffix != '')
                lang_suffix = lang_suffix[0].toUpperCase() + lang_suffix.slice(1);
            return article['title'+lang_suffix];
        },
        getExcerpt(article) {
            var lang_suffix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang;
            if(lang_suffix != '')
                lang_suffix = lang_suffix[0].toUpperCase() + lang_suffix.slice(1);
            return article['content'+lang_suffix];
        },
        getSlug(article) {
            var lang_suffix = this.$store.state.lang == 'cs' ? '' : this.$store.state.lang;
            if(lang_suffix != '')
                lang_suffix = lang_suffix[0].toUpperCase() + lang_suffix.slice(1);
            console.log(lang_suffix);
            return article['slug'+lang_suffix];
        },
        getArticleSlug() {
            if(this.$store.state.lang == 'cs')
                return 'clanek';
            else
                return 'article';
        },
        next() {
            this.$refs.newsListCarousel.next()
        },
        prev() {
            this.$refs.newsListCarousel.prev()
        },
    }
};
</script>