<template>
  <section class="powerCouple pb-1 p2b-1-lg" :class="{'powerCoupleTriple': this.selectedCategory != null && this.selectedCategory.powerCouples.length > 1}">
    <div class="wrap visible">
      <div class="row jcc">
        <div class="powerCoupleTitle col-12 col-lg-8 px-2 py-3 p2y-4-lg px-0-lg">
          <h4>{{$t('page.power_section.headline')}}</h4>
          <p>{{$t('page.power_section.desc')}}</p>
        </div>
        <div class="powerCoupleBoxWrapper col-12 col-lg-8 p-2 py-3-lg p2x-7-lg bg_gl">
          <div class="row">
            <div class="col-12 mb-2 powerCoupleBoxDropWrapper">
              <p><strong>{{$t('page.power_section.choose_cat')}}</strong></p>
              <a href="#" @click="this.handleDropdownClick" class="cat01" data-oki="#cat01" :aria-expanded="this.dropdownOpen">
                <!-- <span class='icon icon-case'></span>  -->
                <span class="val">{{this.getDropdownLabel()}}</span> 
                <span class='icon icon-down'></span>
              </a>

              <div class="okiCollapse okiDropdown powerCoupleBoxDrop" :class="{'open': this.dropdownOpen}" id="cat01">
                <div class="row">
                  <div class="col-12">
                    <ul>
                      <li v-for="(elem, idx) in this.categories" :key="'cat_'+idx"><a href="#" @click="this.handleSelectCategory(elem, $event)">{{this.getCategoryName(elem)}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.selectedCategory != null" class="powerCoupleBox powerCoupleBoxNew col-12">
              <div class="row jcc bg_w">
                <div class="desc col-12 col-lg-10 ta-c mb-2">
                  <p v-html="$t('results.sidebar.power_section.label', {'industry': this.getCategoryName(this.selectedCategory)})" />
                </div>
                <div class="powerCoupleBoxSingle col-12 col-md-6 col-lg-auto">
                    <div class="row aic">
                        <div class="img col-6">
                          <img src="@/assets/dist/img/powerCoupleBoxImg09.svg" alt="lorem">
                            <div class="imgLeft"><img :src="this.selectedCategory.iconWhite" :alt="this.getCategoryName(this.selectedCategory)"></div>
                            <div class="imgRight"><img :src="this.selectedCategory.powerCouples[0].icon" alt="lorem"></div>
                        </div>
                        <div class="desc col-6 ta-c">
                            <div class="descRelatedCatNew">{{this.getPowerCoupleLabel(this.selectedCategory.powerCouples[0])}}</div>
                            <div class="descRelatedCatNewRat" data-rat="3"><span></span><span></span><span></span></div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="row jcc bg_w">
                <div class="desc other col-12 col-lg-10 mb-1"><p>{{$t('page.power_section.other_match')}}</p></div>
                <div v-for="(elem, idx) in this.selectedCategory.powerCouples" :key="'power_couple_'+idx" class="powerCoupleBoxSingle powerCoupleBoxSingleOther col-12 col-lg-10">
                    <template v-if="idx > 0">
                    <div class="row aic">
                        <div class="ico col-auto"><img :src="elem.icon" :alt="$t('results.sidebar.power_section.headline')"></div>
                        <div class="desc col">
                            <div class="descRelatedCatNew">{{this.getRelatedLabel(elem)}}</div>
                        </div>
                        <div class="rat col-auto">
                            <div class="descRelatedCatNewRat" :data-rat="this.selectedCategory.powerCouples.length - idx"><span></span><span></span><span></span></div>
                        </div>
                    </div>
                    </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      selectedCategory: null,
      dropdownOpen: false,
      categories: [],
    }
  },
  mounted() {
    this.$store.dispatch('fetchIndustries').then((data) => {
      this.categories = data;
      if (data.length > 0) {
        this.handleSelectFirstCategory(data[0]);
      }
    });
  },
  methods: {
    handleDropdownClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.dropdownOpen = !this.dropdownOpen;
    },
    handleSelectFirstCategory(category) {
      this.selectedCategory = category;
      this.dropdownOpen = false;
    },
    handleSelectCategory(category, event) {
      event.preventDefault();
      this.selectedCategory = category;
      this.dropdownOpen = false;
      this.reportCategorySelection();
    },
    getCategoryName(category) {
      var lang = this.$store.state.lang;
      var prefix = lang == 'cs' ? '' : lang == 'en' ? 'En' : 'Uk';
      return category['name'+prefix];
    },
    getPowerCoupleLabel(elem) {
      var lang = this.$store.state.lang;
      var prefix = lang == 'cs' ? '' : lang == 'en' ? 'En' : 'Uk';
      return elem['label'+prefix];
    },
    getDropdownLabel()
    {
      if(this.selectedCategory == null) {
        return this.$t('page.power_section.dropdown_label');
      }
      return this.getCategoryName(this.selectedCategory);
    },
    getRelatedLabel(related)
    {
      if(this.selectedCategory == null) {
        return '';
      }
      var lang = this.$store.state.lang;
      var prefix = lang == 'cs' ? '' : lang == 'en' ? 'En' : 'Uk';
      return related['label'+prefix];
    },
    reportCategorySelection()
    {
      var clickInfoObject = {
        "ctaName" : "Select your business category ",
        "ctaType" : "Button",
        "ctaLocation" : "Body",
        "ctaTitle" : "Find complementary businesses to inc. profits",
        "fieldValue1":"category:"+this.getCategoryName(this.selectedCategory)
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});

    }
  }
}
</script>