<template>
    <section class="banner">
        <div class="bg">
            <img class="desktop" v-bind:src=this.getDesktopBanner() v-bind:alt=this.getBannerTitle()>
            <img class="mobile" v-bind:src=this.getMobileBanner() v-bind:alt=this.getBannerTitle()>
        </div>
        <div class="wrap">
            <div class="row" :class="{aic: this.getBannerVideos().length > 0}">
                <div class="bannerCopy col-12 col-lg-5 my-a px-2 p2y-2 p2y-4-lg">
                    <h1 v-html=this.getBannerTitle()></h1>

                    <div v-html=this.getBannerContent()></div>


                    <div class="power desktop" v-if="false">
                        <p><span class="val">POWERED BY</span> <img width="86" height="52" src="@/assets/dist/img/logo.svg" alt="POWERED BY"></p>
                        <p><span class="val">PARTNERSHIPS</span> <img width="177" height="32" src="@/assets/dist/img/morizon.svg" alt="PARTNERSHIPS"></p>
                    </div>
                </div>
                <!-- <div class="bannerImg col-12 col-lg-7 ta-c ta-r-lg"> -->
                <div class="bannerImg col-12 col-lg-6 ml-a" v-if="this.getBannerVideos().length > 0">
                    <video width="100%" height="400" controls v-if="this.getBannerVideos().length == 1" poster="@/assets/dist/img/poster.png">
                        <source :src="this.getBannerVideos()[0].url" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>

                    <VideoListSlider v-else :video-object="this.getBannerVideos()" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VideoListSlider from "@/views/partials/VideoListSlider.vue"

export default {
    name: 'GlobalBannerSection',
    components: {
        VideoListSlider
    },
    data() {
        return {
          page: null
        }
    },
    mounted() {
      //subscribing changes in the store
      this.$store.subscribe(() => {
        this.getPage();
      })
    },
    methods: {
        getPage() {

          var currentSlug = this.$route.params.slug;
          for(var index in this.$store.state.pages) {
            var pageSlugs = [this.$store.state.pages[index].slug, this.$store.state.pages[index].slugEn, this.$store.state.pages[index].slugUk];
            if(pageSlugs.indexOf(currentSlug) != -1) {
              this.page = this.$store.state.pages[index];
              break;
            }
          }

          return this.page;
        },
        getBannerVideos() {
            if(this.page && 'heroVideos' in this.page) {
                return this.page.heroVideos;
            }
            else
                return [];

        },
        getDesktopBanner() {
            if(this.page && 'image' in this.page)
                return this.page.image;
            else
                return '';

        },
        getMobileBanner() {
            if(this.page && 'imageMobile' in this.page)
                return this.page.imageMobile;
            else
                return '';

        },
        getBannerTitle() {
            if(this.page && 'title' in this.page) {
                if(this.$store.state.lang == 'cs')
                    return this.page.hero;
                else if(this.$store.state.lang == 'en')
                    return this.page.heroEn;
                else if(this.$store.state.lang == 'uk')
                    return this.page.heroUk;
            } else
                return '';

        },
        getBannerContent() {
            if(this.page && 'title'  in this.page) {
                if(this.$store.state.lang == 'cs')
                    return this.page.heroContent;
                else if(this.$store.state.lang == 'en')
                    return this.page.heroContentEn;
                else if(this.$store.state.lang == 'uk')
                    return this.page.heroContentUk;
            } else
                return '';

        },
    }
}
</script>