<template>
  <section class="main">
    <div class="bg">
      <img class="desktop" v-bind:src=this.getDesktopBanner() v-bind:alt=this.getBannerTitle()>
      <img class="mobile" v-bind:src=this.getMobileBanner() v-bind:alt=this.getBannerTitle()>
    </div>
    <div class="wrap">
      <div class="row">
        <div class="mainCopy col-12 col-lg-5 my-a p2t-2 p2b-2 px-2 pt-6-lg p2b-6-lg">
          <h1 v-html=this.getBannerTitle()></h1>
          <div v-html=this.getBannerContent()></div>
          <div class="mainCta desktop">
            <router-link @click="this.report()" :to="{'name': 'quiz1', params: {'lang': this.$store.state.lang}}" class="btn">
              <span class="btn-val">{{$t('quiz.start')}}</span>
            </router-link>
          </div>
        </div>
        <!-- <div class="mainImg mainImgAnim-off col-12 col-lg-7 ta-c ta-r-lg">
            <img src='@/assets/dist/img/mainImg05.png' alt='mainImg'>
        </div> -->
        <div class="mainCta mobile col-12 ta-c pb-2 px-2">

            <router-link @click="this.report()" :to="{'name': 'quiz1', params: {'lang': this.$store.state.lang}}" class="btn btn-full">
              <span class="btn-val">{{$t('quiz.start')}}</span>
            </router-link>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TopBannerSection',
  methods: {
    getDesktopBanner() {
      if(('home' in this.$store.state.pages) == false)
        return '';

      var page = this.$store.state.pages['home'];
      if('image' in page)
        return page.image;
      else
        return '';

    },
    getMobileBanner() {
      if(('home' in this.$store.state.pages) == false)
        return '';

      var page = this.$store.state.pages['home'];
      if('imageMobile' in page)
        return page.imageMobile;
      else
        return '';

    },
    getBannerTitle() {


      if(('home' in this.$store.state.pages) == false)
        return '';


      var page = this.$store.state.pages['home'];
      if(page && 'title' in page) {

        if(this.$store.state.lang == 'cs')
          return page.hero;
        else if(this.$store.state.lang == 'en')
          return page.heroEn;
        else if(this.$store.state.lang == 'uk')
          return page.heroUk;
      } else
        return '';

    },
    getBannerContent() {
      if(('home' in this.$store.state.pages) == false)
        return '';
      var page = this.$store.state.pages['home'];
      if(page && 'title'  in page) {
        if(this.$store.state.lang == 'cs')
          return page.heroContent;
        else if(this.$store.state.lang == 'en')
          return page.heroContentEn;
        else if(this.$store.state.lang == 'uk')
          return page.heroContentUk;
      } else
        return '';

    },
    report() {
      var clickInfoObject = {
        "ctaName" : "StartQuiz",
        "ctaType" : "Button",
        "ctaLocation" : "HomeBanner",
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});
    },
  }
}
</script>